define("discourse/plugins/discourse-plugin-composer-help-button/discourse/components/composer-help-content", ["exports", "@ember/component", "discourse/mixins/buffered-content"], function (_exports, _component, _bufferedContent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ComposerHelpContentComponent = _component.default.extend((0, _bufferedContent.bufferedProperty)("model"), {
    tagName: "div"
  });
  var _default = _exports.default = ComposerHelpContentComponent;
});