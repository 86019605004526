define("discourse/plugins/discourse-plugin-composer-help-button/discourse/controllers/composer-help", ["exports", "discourse/mixins/modal-functionality", "discourse/lib/ajax"], function (_exports, _modalFunctionality, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend(_modalFunctionality.default, {
    loading: true,
    parsedContent: '',
    refresh: function () {
      this.set("loading", true);
    },
    init: function () {
      this._super();
      this.setProperties({
        "loading": true,
        "parsedContent": ''
      });
      _ajax.default.ajax(Discourse.SiteSettings.composer_help_modal_url + '.json').then(function (resp) {
        this.set('parsedContent', resp.post_stream.posts[0].cooked);
        this.refresh();
      }.bind(this));
    }
  });
});