define("discourse/plugins/discourse-plugin-composer-help-button/discourse/initializers/composer-help", ["exports", "discourse/lib/plugin-api", "discourse/components/d-editor", "discourse/lib/show-modal"], function (_exports, _pluginApi, _dEditor, _showModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function initializePlugin(api) {
    const siteSettings = api.container.lookup('site-settings:main');
    if (siteSettings.composer_help_enabled && siteSettings.composer_help_modal_url) {
      _dEditor.default.reopen({
        actions: {
          showComposerHelp: function () {
            (0, _showModal.default)('composer-help', {
              title: 'composer_help.title'
            });
          }
        }
      });
      api.onToolbarCreate(toolbar => {
        toolbar.addButton({
          id: "composer_help_button",
          group: "extras",
          icon: "question",
          action: 'showComposerHelp'
        });
      });
    }
  }
  var _default = _exports.default = {
    name: 'composer-help',
    initialize(container) {
      (0, _pluginApi.withPluginApi)('0.1', api => initializePlugin(api));
    }
  };
});