define("discourse/plugins/discourse-plugin-composer-help-button/discourse/templates/components/composer-help-content", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{{buffered.parsedContent}}}
  
  */
  {
    "id": "F8UFGzU6",
    "block": "[[[2,[30,0,[\"buffered\",\"parsedContent\"]]],[1,\"\\n\"],[1,[28,[32,0],[\"[[\\\"The `buffered` property path was used in the `discourse/plugins/discourse-plugin-composer-help-button/discourse/templates/components/composer-help-content.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.buffered}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[]]",
    "moduleName": "discourse/plugins/discourse-plugin-composer-help-button/discourse/templates/components/composer-help-content.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});